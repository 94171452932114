import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Col,
  flex,
  Icon,
  offsets,
  openStatusNotification,
  Row,
  Typography,
  UserTableBlock
} from '@xq/ui-kit';
import { HomePageData, HomeService, HomeServiceApi } from './home-service';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getStatusNotificationTranslations } from '@services/notifications';
import { UserContext, UserContextData } from '@context';
import styles from './Home.module.scss';
import { HomeLearningCard } from '@pages/Home/components';
import cn from 'classnames';
import {
  getAppsLearningInfoCards,
  getHomePageAppsLinks,
  getNewsLinkCard
} from '@pages/Home/constants';
import { NewsCard } from '@pages/Home/components/NewsCard';
import type { NewsItemDTOResponse } from '@xq/home-gateway-frontend-client';

export const Home: FC = () => {
  const service: HomeService = new HomeServiceApi();
  const userContext: UserContextData = useContext(UserContext);
  const { t } = useTranslation();

  const [homePageData, setHomePageData] = useState<HomePageData>(null);
  const [news, setNews] = useState<NewsItemDTOResponse[]>([]);

  async function fetchData() {
    try {
      const response = await service.fetchData();
      setHomePageData(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error
        }
      });
    }
  }

  async function fetchNews() {
    try {
      const response = await service.fetchNews();
      setNews([...response, getNewsLinkCard(t)]);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error
        }
      });
    }
  }

  const appsLearningInfoCards = getAppsLearningInfoCards(t);

  useEffect(() => {
    fetchData();
    fetchNews();
  }, []);

  const homePageAppsLinks = getHomePageAppsLinks(
    userContext.isOrganizationAdmin,
    userContext.isXQAdmin
  );

  return (
    <div
      className={styles.home}
      style={{
        backgroundImage:
          "url('https://cdn.exquance.com/frontend/apps/home/home-page-background.jpg')"
      }}
    >
      <Row
        cols={10}
        className={cn(flex['align-items-start'], offsets['mb-60'])}
      >
        <Col col={10}>
          <Typography className={offsets['mb-40']} variant="h2" element={'div'}>
            {`${t('home.welcome')} ${userContext.firstName}!`}
          </Typography>

          <Typography
            className={offsets['mb-20']}
            variant="body-2"
            element={'div'}
          >
            {t('home.yourApplications')}
          </Typography>
        </Col>

        <Col col={10} md={7} className={styles['app-cards-col']}>
          <div className={styles['app-cards']}>
            {homePageAppsLinks.map((card) => {
              return (
                <a
                  href={card.url}
                  target={'_blank'}
                  className={styles['app-card']}
                  key={card.alt}
                >
                  <img alt={card.alt} src={card.image} />
                  <Icon
                    name={'arrow-right'}
                    size={'s'}
                    className={styles['card-icon']}
                  />
                </a>
              );
            })}
          </div>
        </Col>

        <Col col={10} sm={8} md={3} className={styles['user-info-col']}>
          <NavLink className={styles['user-info-card']} to={'settings'}>
            <UserTableBlock
              showAvatar={true}
              user={{
                avatar: userContext.avatar,
                fullname: userContext.firstName,
                email: userContext.email,
                organizationName: userContext.organizationName
              }}
            />
            <Icon
              name={'arrow-right'}
              size={'s'}
              className={styles['card-icon']}
            />
          </NavLink>
        </Col>
      </Row>

      <Row cols={10} className={flex['align-items-start']}>
        <Col col={10}>
          <Typography
            className={offsets['mb-20']}
            variant="body-2"
            element={'div'}
          >
            {t('home.learningCenter')}
          </Typography>
        </Col>
        <Col col={10} md={7} className={styles['app-cards-col']}>
          <div className={styles['learning-cards']}>
            {appsLearningInfoCards.map((card) => {
              return (
                <HomeLearningCard
                  id={card.id}
                  key={card.id}
                  link={card.link}
                  text={card.text}
                  image={card.image}
                />
              );
            })}
          </div>
        </Col>
        <Col col={10} sm={8} md={3} className={styles['user-info-col']}>
          {homePageData?.isNewMTVersionAvailable && (
            <NavLink className={styles['mt-version-card']} to={'releases'}>
              <Icon name={'bell'} size={'m'} color={'warning'} />
              <div>
                <Typography
                  variant={'body-2'}
                  element={'div'}
                  className={styles['mt-version-card_title']}
                >
                  {t('home.newVersionModelTreeAvailable')}
                </Typography>

                <Typography variant={'body-3'} element={'div'}>
                  {t('home.checkDetailsInReleaseNotes')}
                </Typography>
              </div>
              <Icon
                name={'arrow-right'}
                size={'s'}
                className={styles['card-icon']}
              />
            </NavLink>
          )}
        </Col>
      </Row>

      <Row cols={10} className={offsets['mt-60']}>
        <Col col={10}>
          <Typography
            className={offsets['mb-20']}
            variant="body-2"
            element={'div'}
          >
            {t('home.newsAndUpdates')}
          </Typography>
        </Col>
        <Col col={10} className={styles['app-cards-col']}>
          <div className={styles['news-cards']}>
            {news?.map((card, key) => {
              return (
                <NewsCard
                  key={key}
                  title={card.title}
                  link={card.slug}
                  description={card.description}
                />
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

Home.displayName = 'Home';
