import { HeaderAppItem, OrganizationItem } from '@xq/ui-kit';
import { generateHeaderAppItems, handleBackendError, homeApiConfiguration, iamGateway } from '@services';
import { LayoutApi, LayoutDTOResponse } from '@xq/home-gateway-frontend-client';

export interface UserLayoutData {
  firstName: string;
  lastName: string;
  organization: OrganizationItem;
  email: string;
  avatar: string;
  apps: HeaderAppItem[];
  languageIso2: string;
  isXQAdmin: boolean;
  isOrganizationAdmin: boolean;
}

export interface LayoutService {
  fetchData(): Promise<UserLayoutData>;

  authCheck(): Promise<void>;

  logout(): Promise<void>;
}

const layoutPageGateway = new LayoutApi(homeApiConfiguration);

export class LayoutServiceApi implements LayoutService {
  async fetchData(): Promise<UserLayoutData> {
    try {
      const response: LayoutDTOResponse =
        await layoutPageGateway.layoutControllerGetLayout();

      return {
        firstName: response?.firstName || '',
        lastName: response?.lastName || '',
        email: response?.email || '',
        avatar: response?.avatarUrl,
        languageIso2: response?.languageIso2,
        organization: {
          name: response?.organization?.name,
          uuid: response?.organization?.uuid
        },
        apps: generateHeaderAppItems(
          response?.isOrganizationAdmin,
          response?.isXQAdmin
        ),
        isOrganizationAdmin: response?.isOrganizationAdmin,
        isXQAdmin: response?.isXQAdmin
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async authCheck(): Promise<void> {
    try {
      await iamGateway.authV2ControllerCheck();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.authCheck.name
      );
    }
  }

  async logout(): Promise<void> {
    try {
      await iamGateway.authV2ControllerLogout();
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.logout.name);
    }
  }
}
