import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './Releases.module.scss';
import {
  Button,
  Col,
  Divider,
  downloadFileByLink,
  Icon,
  LayoutContainer,
  offsets,
  openStatusNotification,
  reactMarkdownConfig,
  Row,
  Typography
} from '@xq/ui-kit';
import { ReleasesService, ReleasesServiceApi } from './releases-service';
import { useTranslation } from 'react-i18next';
import { errorLog } from '@services/logger';
import { getStatusNotificationTranslations } from '@services/notifications';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { ModelTreeRelease, ReleasesList } from '@pages/Releases/dataTypes';
import { formatDateWithLongMoth } from '@services';
import { ReleaseNoteLink } from '@pages/Releases/components/ReleaseNoteLink/ReleaseNoteLink';
import { getShortAppsLearningInfoCards } from '@pages/Releases/constants';
import { UserContext, UserContextData } from '@context';
import { ThankYouModal } from '@pages/Releases/components/ThankYouModal';

export const descriptionParts = [
  'A modern-day cash flow modeling platform for real estate and other types of assets and investment structures.',
  'Track and forecast asset, company, or loan cash flows and take out reports through comprehensive reporting functionalities.',
  'Cash flow models and reports are exportable to Excel with all calculations and formulas included.'
];

export const minimumSystemRequirements = 'Windows 8.1, 8Gb RAM, 4 core CPU';
export const recommendedSystemRequirements =
  'Windows 10, 16Gb RAM, Ryzen 5 (3rd Gen) /Intel i5 (7th Gen+)';

const RELEASE_NOTES_PER_PAGE = 5;

export const Releases: FC = () => {
  const service: ReleasesService = new ReleasesServiceApi();
  const { t } = useTranslation();
  const params = useParams();
  const userContext: UserContextData = useContext(UserContext);

  const [lastReleaseVersion, setLastReleaseVersion] = useState<string>(null);
  const [organizationAssignedVersion, setOrganizationAssignedVersion] =
    useState<string>(null);
  const [shownReleaseNoteVersion, setShownReleaseNoteVersion] =
    useState<string>(null);
  const [releaseInfo, setReleaseInfo] = useState<ModelTreeRelease>(null);
  const [releasesList, setReleasesList] = useState<ReleasesList>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isReleaseLoading, setIsReleaseLoading] = useState<boolean>(false);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  async function fetchReleasesList(page?: number) {
    try {
      const response = await service.getReleasesList(
        page,
        RELEASE_NOTES_PER_PAGE
      );
      setReleasesList(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        message: t('notifications.fetchDataError'),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function fetchNextPageReleasesList() {
    if (releasesList?.meta?.currentPage < releasesList?.meta?.totalPages) {
      await fetchReleasesList(releasesList?.meta?.currentPage + 1);
    }
  }

  async function fetchPrevPageReleasesList() {
    if (releasesList?.meta?.currentPage > 1) {
      await fetchReleasesList(releasesList?.meta?.currentPage - 1);
    }
  }

  async function fetchPageData() {
    setLoading(true);

    try {
      const response = await service.fetchPageData();
      setLastReleaseVersion(response?.lastReleaseVersion);
      setOrganizationAssignedVersion(response?.organizationAssignedVersion);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        message: t('notifications.fetchDataError'),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      errorLog(error, `fetchPageData in releases page`);
    } finally {
      setLoading(false);
    }
  }

  async function getReleaseInfo(releaseVersion: string) {
    try {
      setIsReleaseLoading(true);
      const response = await service.getReleaseInfo(releaseVersion);
      setReleaseInfo(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        message: t('notifications.fetchDataError'),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      errorLog(error, `getReleaseInfo in releases page`);
    } finally {
      setIsReleaseLoading(false);
    }
  }

  async function downloadRelease() {
    try {
      // if (!organizationAssignedVersion) {
      //   openStatusNotification({
      //     translations: getStatusNotificationTranslations(t),
      //     status: 500 //todo add message why organizationAssignedVersion is not set
      //   });
      //   return;
      // }
      const downloadFileUrl = await service.getDownloadReleaseFileUrl();
      await downloadFileByLink(
        downloadFileUrl,
        `ModelTree_Desktop_${organizationAssignedVersion}`
      );

      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('releases.modelTreeDesktopAppHasStartedDownloading')
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        message: 'An error occurred when downloading the file',
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      errorLog(error, `downloadRelease in releases page`);
    }
  }

  async function requestUpdate() {
    try {
      await service.requestNewModelTreeVersion();
      setIsModalShown(true);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      errorLog(error, `requestUpdate in releases page`);
    }
  }

  useEffect(() => {
    fetchPageData();
    fetchReleasesList();
  }, []);

  useEffect(() => {
    if (params?.releaseVersion) {
      setShownReleaseNoteVersion(params?.releaseVersion);
    } else if (lastReleaseVersion) {
      setShownReleaseNoteVersion(lastReleaseVersion);
    }
  }, [params?.releaseVersion, lastReleaseVersion]);

  useEffect(() => {
    if (shownReleaseNoteVersion) {
      getReleaseInfo(shownReleaseNoteVersion);
    }
  }, [shownReleaseNoteVersion]);

  const appsLearningShortInfoCards = getShortAppsLearningInfoCards(t);

  return (
    <LayoutContainer className={offsets['mt-60']}>
      {!isLoading && (
        <div>
          <Row cols={10}>
            <Col col={10}>
              <div className={styles['model-tree-title']}>
                <Icon
                  name={'logo-model-tree-small'}
                  className={styles['logo-icon']}
                />
                <Typography variant="h1" className={offsets['mb-20']}>
                  ModelTree
                </Typography>
              </div>
            </Col>
          </Row>
          <Row cols={10}>
            <Col col={10} md={6}>
              {descriptionParts?.map((part, key) => {
                return (
                  <Typography
                    key={key}
                    element={'div'}
                    className={offsets['mb-20']}
                    variant={'body-1'}
                  >
                    {part}
                  </Typography>
                );
              })}

              <Typography
                element={'div'}
                className={offsets['mt-40']}
                variant={'body-2'}
              >
                {t('releases.minimumSystemRequirements')}:
              </Typography>

              <Typography
                element={'div'}
                className={offsets['mb-20']}
                variant={'body-1'}
              >
                {minimumSystemRequirements}
              </Typography>

              <Button
                type={'primary'}
                onClick={downloadRelease}
                className={offsets['mb-20']}
              >
                {`${t('releases.download')} v.${organizationAssignedVersion}`}
              </Button>

              {organizationAssignedVersion !== lastReleaseVersion && (
                <Button
                  type={'secondary'}
                  onClick={requestUpdate}
                  className={cn(offsets['mb-20'], offsets['ml-20'])}
                >
                  {`${t('releases.requestUpdateTo')} v.${lastReleaseVersion}`}
                </Button>
              )}
            </Col>
            <Col col={1} />
            <Col col={10} md={3}>
              <div className={styles['learning-info']}>
                {appsLearningShortInfoCards.map((card, key) => {
                  return (
                    <a
                      key={key}
                      className={styles['learning-info-card']}
                      href={card.link}
                      target={'_blank'}
                    >
                      <Button icon={card.icon} type={'fourth'}>
                        {card.text}
                      </Button>
                    </a>
                  );
                })}
              </div>
            </Col>
          </Row>

          <Row cols={10}>
            <Col col={10} md={6} className={offsets['mt-60']}>
              <Typography
                element={'div'}
                className={offsets['mb-8']}
                variant={'h2'}
              >
                {`${t('releases.version')} ${
                  params.releaseVersion
                    ? params.releaseVersion
                    : lastReleaseVersion
                } ${t('releases.hasReleased')}!`}
              </Typography>

              <Typography
                element={'div'}
                className={cn(
                  offsets['mb-40'],
                  isReleaseLoading
                    ? styles['release-notes--hidden']
                    : styles['release-notes']
                )}
                variant={'body-3'}
              >
                {formatDateWithLongMoth(releaseInfo?.publishDate)}
              </Typography>

              <ReactMarkdown
                className={
                  isReleaseLoading
                    ? styles['release-notes--hidden']
                    : styles['release-notes']
                }
                components={reactMarkdownConfig}
              >
                {releaseInfo?.notes}
              </ReactMarkdown>
            </Col>
            <Col col={1} />

            <Col col={10} md={3}>
              <Typography
                variant={'h5'}
                element={'div'}
                className={offsets['mb-40']}
              >
                {t('releases.allReleaseNotes')}
              </Typography>

              <Divider />

              {releasesList?.items?.map((note, key) => {
                return <ReleaseNoteLink key={key} releaseNote={note} />;
              })}

              <div className={styles.pagination}>
                <Button
                  disabled={releasesList?.meta?.currentPage <= 1}
                  icon={'chevron-left'}
                  type={'fifth'}
                  onClick={fetchPrevPageReleasesList}
                  className={offsets['mr-20']}
                />
                <Button
                  disabled={
                    releasesList?.meta?.currentPage >=
                    releasesList?.meta?.totalPages
                  }
                  icon={'chevron-right'}
                  type={'fifth'}
                  onClick={fetchNextPageReleasesList}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}

      <ThankYouModal
        isOpen={isModalShown}
        onClose={() => setIsModalShown(false)}
      />
    </LayoutContainer>
  );
};

Releases.displayName = 'Releases';
