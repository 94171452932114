import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Header,
  HeaderApp,
  HeaderAppNames,
  NavItem,
  NotificationContainer,
  openStatusNotification
} from '@xq/ui-kit';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  LayoutService,
  LayoutServiceApi,
  UserLayoutData
} from './layout-service';
import { UserContext } from '@context';
import styles from './Layout.module.scss';
import { getStatusNotificationTranslations } from '@services/notifications';
import { redirectToSSOLoginPage } from '@services';

const currentApp: HeaderApp = HeaderAppNames.Home;

export const Layout: FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const service: LayoutService = new LayoutServiceApi();
  const location = useLocation();

  async function logout() {
    try {
      await service.logout();
      redirectToSSOLoginPage();
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const [userData, setUserData] = useState<UserLayoutData>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [organizationName, setOrganizationName] = useState<string>('');
  const [isOrganizationAdmin, setIsOrganizationAdmin] =
    useState<boolean>(false);
  const [isXQAdmin, setIsXQAdmin] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string>('');
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);

  const fullName = useMemo(() => {
    if (firstName && lastName) {
      return firstName + ' ' + lastName;
    }
    if (firstName) {
      return firstName;
    }
    if (lastName) {
      return lastName;
    }
    return '';
  }, [firstName, lastName]);

  const [userContextData, setUserContextData] = useState({
    firstName,
    lastName,
    avatar,
    email,
    organizationName,
    isOrganizationAdmin,
    isXQAdmin,
    setFirstName,
    setLastName,
    setAvatar
  });

  const initValues = () => {
    if (userData) {
      setFirstName(userData?.firstName);
      setLastName(userData?.lastName);
      setEmail(userData?.email);
      setAvatar(userData?.avatar);
      setUserContextData({
        ...userContextData,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        avatar: userData?.avatar,
        email: userData?.email,
        organizationName: userData?.organization?.name,
        isOrganizationAdmin: userData?.isOrganizationAdmin,
        isXQAdmin: userData?.isXQAdmin
      });
    }
  };

  async function fetchData() {
    try {
      const response = await service.fetchData();
      setUserData(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        message: t('notifications.fetchDataError'),
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function authCheck() {
    try {
      await service.authCheck();
      setIsAuthChecked(true);
    } catch (error) {
      setIsAuthChecked(false);
      const status = error.status;
      if (status === 401) {
        redirectToSSOLoginPage();
      } else {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: error?.status,
          error: {
            details: error?.details,
            code: error?.error,
            message: error?.message
          }
        });
      }
    }
  }

  useEffect(() => {
    if (userData?.languageIso2) {
      i18n.changeLanguage(userData?.languageIso2);
      if (localStorage.getItem('lang') !== userData?.languageIso2) {
        localStorage.setItem('lang', userData?.languageIso2);
      }
    }
  }, [userData?.languageIso2]);

  useEffect(() => {
    authCheck().then(() => {
      fetchData();
    });
  }, []);

  useEffect(() => {
    initValues();
  }, [userData]);

  const profileLinks: NavItem[] = [
    {
      name: t('settings.userSettings'),
      path: '/settings', //todo add constants
      isActive: location.pathname === '/settings'
    }
  ];

  return (
    <Fragment>
      {isAuthChecked && (
        <div className={styles.layout}>
          <Header
            className={styles.header}
            apps={userData?.apps}
            avatar={avatar}
            organizations={[userData?.organization]}
            currentOrganizationId={userData?.organization.uuid}
            name={fullName}
            currentApp={currentApp}
            NavLink={NavLink}
            profileLinks={profileLinks}
            onSignOut={logout}
            translations={{
              signOut: t('routes.signOut')
            }}
          />
          <main>
            <UserContext.Provider value={userContextData}>
              <Outlet />
            </UserContext.Provider>
          </main>
          <NotificationContainer />
        </div>
      )}
    </Fragment>
  );
};

Layout.displayName = 'Layout';
