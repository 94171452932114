import React, { FC } from 'react';
import styles from './NewsCard.module.scss';
import { Icon, offsets, Typography } from '@xq/ui-kit';
import cn from 'classnames';
import { config } from '@config';

export interface NewsItem {
  title: string;
  description?: string;
  link: string;
}

export const NewsCard: FC<NewsItem> = (props) => {
  const { link, title, description } = props;

  const getHref = () => {
    if (link) {
      return `${config.exquanceLandingNewsPage}/${link}`;
    }
    return config.exquanceLandingNewsPage;
  };

  return (
    <div>
      <a href={getHref()} target={'_blank'} className={cn(styles.card)}>
        <div>
          <Typography
            element={'div'}
            className={styles.text}
            variant={'body-1'}
          >
            {title}
            <Icon className={styles.arrow} name={'arrow-right'} size={'s'} />
          </Typography>
        </div>
        <Typography
          variant={'body-3'}
          element={'div'}
          className={offsets['mt-6']}
        >
          {description}
        </Typography>
      </a>
    </div>
  );
};

NewsCard.displayName = 'NewsCard';
