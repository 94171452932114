import { handleBackendError, homeApiConfiguration } from '@services';
import { ModelTreeRelease, ReleasesList } from '@pages';
import {
  ModelTreeReleasePageDataDTOResponse,
  ModelTreeReleasesPageApi
} from '@xq/home-gateway-frontend-client';

export interface ReleasesService {
  fetchPageData(): Promise<ModelTreeReleasePageDataDTOResponse>;

  getReleasesList(page?: number, limit?: number): Promise<ReleasesList>;

  getReleaseInfo(releaseVersion?: string): Promise<ModelTreeRelease>;

  getDownloadReleaseFileUrl(): Promise<string>;

  requestNewModelTreeVersion(): Promise<void>;
}

const mainPageGateway = new ModelTreeReleasesPageApi(homeApiConfiguration);

export class ReleasesServiceApi implements ReleasesService {
  async fetchPageData(): Promise<ModelTreeReleasePageDataDTOResponse> {
    try {
      return await mainPageGateway.modelTreeReleasesPageControllerGetPageData();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchPageData.name
      );
    }
  }

  async getReleasesList(page?: number, limit?: number): Promise<ReleasesList> {
    try {
      return await mainPageGateway.modelTreeReleasesPageControllerGetReleaseList(
        { page, limit }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getReleasesList.name
      );
    }
  }

  async getReleaseInfo(releaseVersion: string): Promise<ModelTreeRelease> {
    try {
      return await mainPageGateway.modelTreeReleasesPageControllerGetReleaseInfo(
        { modelTreeReleaseVersion: releaseVersion }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getReleaseInfo.name
      );
    }
  }

  async getDownloadReleaseFileUrl(): Promise<string> {
    try {
      return await mainPageGateway.modelTreeReleasesPageControllerGetDowloadAssignedReleaseFileUrl();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getDownloadReleaseFileUrl.name
      );
    }
  }

  async requestNewModelTreeVersion(): Promise<void> {
    try {
      await mainPageGateway.modelTreeReleasesPageControllerRequestForUpdateModeltreeVersion();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.requestNewModelTreeVersion.name
      );
    }
  }
}
