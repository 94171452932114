import { handleBackendError, iamGateway } from '@services';
import { AuthV2ApiAuthV2ControllerChangePasswordRequest } from '@xq/iam-frontend-client';

export interface ChangePasswordServiceForm {
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordService {
  changePassword(model: ChangePasswordServiceForm): Promise<void>;
}

export class ChangePasswordServiceApi implements ChangePasswordService {
  async changePassword(model: ChangePasswordServiceForm): Promise<void> {
    try {
      const requestParameters: AuthV2ApiAuthV2ControllerChangePasswordRequest = {
        authV2DTORequestChangePassword: {
          newPassword: model.newPassword,
          currentPassword: model.oldPassword
        }
      };
      await iamGateway.authV2ControllerChangePassword(requestParameters);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.changePassword.name
      );
    }
  }
}
