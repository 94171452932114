import * as React from 'react';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  ChangePassword,
  Forbidden,
  Home,
  InternalServerError,
  NotFound,
  Releases,
  Settings
} from '@pages';
import { Layout } from './layouts/Layout';

export const App: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Home />} />
        <Route path="settings" element={<Settings />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="model-tree" element={<ChangePassword />} />
        <Route path="releases/:releaseVersion" element={<Releases />} />
        <Route path="releases" element={<Releases />} />
      </Route>
      <Route path="*" element={<NotFound />} />
      <Route path="internal-error" element={<InternalServerError />} />
      <Route path="forbidden" element={<Forbidden />} />
    </Routes>
  );
};
