import React, { FC } from 'react';
import { common, display, Link, offsets, Typography } from '@xq/ui-kit';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { formatDateWithLongMoth } from '@services';
import { ShortReleaseNote } from '@pages';

interface ReleaseNoteLinkProps {
  releaseNote: ShortReleaseNote;
}

export const ReleaseNoteLink: FC<ReleaseNoteLinkProps> = (props) => {
  const { releaseNote } = props;

  return (
    <div className={offsets['mt-40']}>
      <Link
        NavLink={NavLink}
        isLocalRoute={true}
        to={`/releases/${releaseNote.version}`}
        type={'button'}
        className={cn(
          display['d-block'],
          offsets['pb-8'],
          common['no-text-transform']
        )}
      >
        v.{releaseNote.version}{' '}
        {formatDateWithLongMoth(releaseNote?.publishDate)}
      </Link>

      <Typography
        variant={'body-1'}
        element={'div'}
        className={offsets['mb-40']}
      >
        {releaseNote.description}
      </Typography>
    </div>
  );
};

ReleaseNoteLink.displayName = 'ReleaseNoteLink';
