export const config = {
  notificationDelay: 20000,
  apiUrl: `${process.env.API_URL}/api`,
  cdnUrl: 'https://cdn.exquance.com',
  slackUrl: process.env.SLACK_WEBHOOK,

  ssoFrontendLoginUrl: process.env.FRONTEND_SSO_LOGIN_URL,
  setResetPasswordUrl: process.env.FRONTEND_SSO_RESET_PASSWORD_URL,
  administrationFrontendUrl: process.env.FRONTEND_ADMINISTRATION_URL,
  omniFrontendUrl: process.env.FRONTEND_OMNI_URL,
  homeFrontendUrl: process.env.FRONTEND_HOME_URL,
  accountFrontendUrl: process.env.FRONTEND_ACCOUNT_URL,
  insightsFrontendUrl: process.env.FRONTEND_INSIGHTS_URL,
  logLevel: process.env.APP_LOG_LEVEL || 'log',
  exquanceLanding: 'https://www.exquance.com',
  exquanceLandingNewsPage: 'https://www.exquance.com/company/blog',

  defaultLang: 'EN',
  localStorageLang: 'lang'
};
