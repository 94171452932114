import React from 'react';

export interface UserContextData {
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
  organizationName: string;
  isOrganizationAdmin: boolean;
  isXQAdmin: boolean;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setAvatar: (value: string) => void;
}

const userContextData: UserContextData = {
  firstName: '',
  lastName: '',
  avatar: '',
  email: '',
  organizationName: '',
  isOrganizationAdmin: false,
  isXQAdmin: false,
  setFirstName: () => {},
  setLastName: () => {},
  setAvatar: () => {}
};

export const UserContext =
  React.createContext<UserContextData>(userContextData);
