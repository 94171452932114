import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Image, offsets, Typography } from '@xq/ui-kit';
import styles from './ThankYouModal.module.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

interface ThankYouModalProps {
  isOpen: boolean;

  onClose(): void;
}

export const ThankYouModal: FC<ThankYouModalProps> = (props) => {
  const { isOpen, onClose } = props;
  const modalRef = useRef<HTMLDialogElement>(null);
  const { t } = useTranslation();

  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [modalClasses, setModalClasses] = useState(styles.modal);

  const close = () => {
    const { current } = modalRef;

    setModalClasses(cn(styles.modal, styles.hide));

    current.addEventListener(
      'animationend',
      () => {
        setModalClasses(styles.modal);
        // @ts-ignore
        current.close();
      },
      { once: true }
    );

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    const { current } = modalRef;
    if (current && isOpen) {
      // todo check why typescript doesn't have showModal and other dialog attributes
      // @ts-ignore
      current.showModal();
      isOpen && current.scroll({ top: 0 });
    }
  }, [isOpen]);

  useEffect(() => {
    // @ts-ignore
    if (modalRef?.current?.open) {
      setIsModalShown(true);
    }

    // @ts-ignore
  }, [modalRef?.current?.open]);

  useEffect(() => {
    return () => {
      setIsModalShown(false);
    };
  }, []);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  return (
    <dialog ref={modalRef} className={modalClasses}>
      <div className={styles.content}>
        <Image
          width={310}
          height={340}
          src={
            'https://cdn.exquance.com/frontend/apps/home/common/thank-you-feedback-image.svg'
          }
        />
        <div className={styles['content-right']}>
          <div>
            <Typography className={offsets['mb-10']} variant="h4" element="div">
              {t('common.thankYou')}
            </Typography>
            <Typography variant="body-1" element="div">
              {t('notifications.yourRequestSuccessfullySubmitted')}
            </Typography>
          </div>

          <div className={styles.buttons}>
            <Button type={'secondary'} onClick={close}>
              {t('common.close')}
            </Button>
          </div>
        </div>
      </div>
    </dialog>
  );
};

ThankYouModal.displayName = 'ThankYouModal';
