import { config } from '@config';
import packageJson from '../../package.json';
import { AuthV2Api, Configuration as IamConfiguration, ConfigurationParameters } from '@xq/iam-frontend-client';
import { Configuration as HomeConfiguration } from '@xq/home-gateway-frontend-client';

export interface AppVersion {
  version: string;
  name: string;
}

export const appVersion: AppVersion = {
  version: packageJson.version,
  name: packageJson.name
};

const getConfiguration = (basePath: string): ConfigurationParameters => {
  return {
    basePath: basePath,
    credentials: 'include',
    headers: {
      'xq-app-version': appVersion.version,
      'xq-app-name': appVersion.name
    }
  };
};

export const iamGateway = new AuthV2Api(
  new IamConfiguration(getConfiguration(config.apiUrl))
);

export const homeApiConfiguration = new HomeConfiguration(
  getConfiguration(`${config.apiUrl}/home`)
);
