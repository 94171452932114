import { TFunction } from 'i18next';
import {
  CONTACT_SUPPORT_LINK,
  MODEL_TREE_QUICK_START_GUIDE_LINK,
  MODEL_TREE_TUTORIALS_LINK
} from '@constants';
import { IconNames } from '@xq/ui-kit';

export interface ReleasesLearningCardProps {
  link: string;
  text: string;
  icon: IconNames;
}

export const getShortAppsLearningInfoCards = (
  t: TFunction
): ReleasesLearningCardProps[] => [
  {
    link: MODEL_TREE_QUICK_START_GUIDE_LINK,
    icon: 'book-upload',
    text: t('releases.quickStartGuide')
  },
  {
    link: MODEL_TREE_TUTORIALS_LINK,
    icon: 'youtube',
    text: t('releases.videoTutorials')
  },
  {
    link: CONTACT_SUPPORT_LINK,
    icon: 'message-report',
    text: t('releases.contactSupport')
  }
];
