import { FormEvent } from 'react';
import { config } from '@config';
import {
  generateHeaderAppSwitcherItems,
  getMonthName,
  HeaderAppItem,
  HeaderAppNames,
  HeaderAppSwitcherUrls
} from '@xq/ui-kit';
import DOMPurify from 'dompurify';

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const generateHeaderAppItems = (
  isOrganizationAdmin?: boolean,
  isXQAdmin?: boolean
): HeaderAppItem[] => {
  const accountApp: HeaderAppItem = {
    name: HeaderAppNames.Home,
    url: '/'
  };

  const headerAppUrls: HeaderAppSwitcherUrls = {
    insightsFrontendUrl: config.insightsFrontendUrl,
    administrationFrontendUrl: config.administrationFrontendUrl,
    omniFrontendUrl: config.omniFrontendUrl,
    homeFrontendUrl: config.homeFrontendUrl
  };

  return [
    accountApp,
    ...generateHeaderAppSwitcherItems(
      headerAppUrls,
      isOrganizationAdmin,
      isXQAdmin
    )
  ];
};

export const submitForm = (e: FormEvent) => {
  e.preventDefault();
};

export const redirectToSSOLoginPage = () => {
  const ssoUrl = `${config.ssoFrontendLoginUrl}?redirectUrl=${window.location.href}`;
  window.location.replace(ssoUrl);
};

export function formatDateWithLongMoth(date: Date): string {
  if (!date) {
    return '';
  }
  const day = date.getDate();
  const month = getMonthName(date);
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export function sanitizeHTML(htmlContent: string) {
  return DOMPurify.sanitize(htmlContent, { USE_PROFILES: { html: true } });
}
