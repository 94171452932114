import {
  getLicenseNameByUUID,
  LicenseApp,
  SelectItem,
  uploadFileBySignedLink
} from '@xq/ui-kit';
import { BackendLang } from '@interfaces';
import { handleBackendError, homeApiConfiguration } from '@services';
import {
  AllApiUserSettingsControllerPostUserSettingsRequest,
  FileSaveLinkDTOResponse,
  UserLicenseDTOResponse,
  UserSettingsApi,
  UserSettingsDTOResponse
} from '@xq/home-gateway-frontend-client';
import {
  UserSettingsData,
  UserUpdatedSettings
} from '@pages/Settings/interfaces';

const settingsPageGateway = new UserSettingsApi(homeApiConfiguration);

export interface SettingsService {
  fetchData(): Promise<UserSettingsData>;

  save(settings: UserUpdatedSettings): Promise<void>;

  uploadAvatar(file: File): Promise<void>;
}

export class SettingsServiceApi implements SettingsService {
  async fetchData(): Promise<UserSettingsData> {
    try {
      const response: UserSettingsDTOResponse =
        await settingsPageGateway.userSettingsControllerGetUserSettings();
      return {
        email: response?.email,
        lang: response?.languageIso2,
        organization: response?.organizationName,
        fromAzure: response?.fromAzure,
        licenses: response?.licenses?.map((licence: UserLicenseDTOResponse) => {
          return {
            app: getLicenseNameByUUID(
              licence?.licenseProduct?.uuid
            ) as LicenseApp,
            licenceType: licence?.typeName,
            describingText: licence?.licenseProduct?.description,
            expiryDate: licence?.expireAt?.toISOString(),
            isTrial: licence?.isTrial,
            selectedRole: {
              label: licence?.roleName,
              value: licence?.roleName
            }
          };
        }),
        languages: response?.allowedLanguages?.map(
          (lang: BackendLang): SelectItem => {
            return { value: lang.iso2, label: lang.name };
          }
        )
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(settings: UserUpdatedSettings): Promise<void> {
    try {
      const params: AllApiUserSettingsControllerPostUserSettingsRequest = {
        userSettingsDTORequestUpdate: {
          firstName: settings.firstName,
          lastName: settings.lastName,
          languageIso2: settings.lang
        }
      };

      await settingsPageGateway.userSettingsControllerPostUserSettings(params);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }

  async uploadAvatar(file: File): Promise<void> {
    try {
      const fileSaveLink: FileSaveLinkDTOResponse =
        await settingsPageGateway.userSettingsControllerGetUploadUserAvatarUrl({
          fileInfoDTORequest: {
            byteSize: file.size,
            name: file.name
          }
        });

      await uploadFileBySignedLink(fileSaveLink, file);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}
