import React, { FC, Fragment } from 'react';
import styles from './HomeLearningCard.module.scss';
import { Icon, Typography } from '@xq/ui-kit';

export interface HomeLearningCardProps {
  link: string;
  text: string;
  id: string;
  image?: string;
}

export const HomeLearningCard: FC<HomeLearningCardProps> = (props) => {
  const { link, text, image } = props;

  return (
    <Fragment>
      <a href={link} target={'_blank'} className={styles.card}>
        {image && (
          <div className={styles['image-container']}>
            <div
              style={{ backgroundImage: `url(${image})` }}
              className={styles.image}
            ></div>
          </div>
        )}

        <div className={styles['text-block']}>
          <Typography
            element={'div'}
            className={styles.text}
            variant={'body-1'}
          >
            <div dangerouslySetInnerHTML={{ __html: text }} />
            <Icon className={styles.arrow} name={'arrow-right'} size={'s'} />
          </Typography>
        </div>
      </a>
    </Fragment>
  );
};

HomeLearningCard.displayName = 'HomeLearningCard';
