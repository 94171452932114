import { config } from '@config';
import { HomeLearningCardProps } from '@pages/Home/components';
import { TFunction } from 'i18next';
import {
  CONTACT_SUPPORT_LINK,
  MODEL_TREE_QUICK_START_GUIDE_LINK,
  MODEL_TREE_TUTORIALS_LINK
} from '@constants';
import { NewsItem } from '@pages/Home/components/NewsCard';

interface AppLinks {
  url: string;
  image: string;
  alt: string;
}

export const getHomePageAppsLinks = (
  isOrganizationAdmin: boolean,
  isXQAdmin: boolean
): AppLinks[] => {
  const links = [
    {
      url: `/releases`,
      image:
        'https://cdn.exquance.com/frontend/apps/home/app-cards/model-tree-app.svg',
      alt: 'model-tree-app'
    },
    {
      url: config.insightsFrontendUrl,
      image:
        'https://cdn.exquance.com/frontend/apps/home/app-cards/insights-app.svg',
      alt: 'insights-app'
    }
  ];

  if (isOrganizationAdmin) {
    links.push({
      url: config.administrationFrontendUrl,
      image:
        'https://cdn.exquance.com/frontend/apps/home/app-cards/admin-app.svg',
      alt: 'admin-app'
    });
  }

  if (isXQAdmin) {
    links.push({
      url: config.omniFrontendUrl,
      image:
        'https://cdn.exquance.com/frontend/apps/home/app-cards/omni-app.svg',
      alt: 'omni-app'
    });
  }

  return links;
};

export const getAppsLearningInfoCards = (
  t: TFunction
): HomeLearningCardProps[] => [
  {
    id: 'start_guide',
    link: MODEL_TREE_QUICK_START_GUIDE_LINK,
    text: t('home.modelTreeQuickStartGuideText'),
    image:
      'https://cdn.exquance.com/frontend/apps/home/quick-start-guide-image.jpeg'
  },
  {
    id: 'tutorials',
    link: MODEL_TREE_TUTORIALS_LINK,
    text: t('home.modelTreeTutorialVideosText'),
    image:
      'https://cdn.exquance.com/frontend/apps/home/model-tree-tutorials.jpeg'
  },
  {
    id: 'support',
    link: CONTACT_SUPPORT_LINK,
    text: t('home.contactSupportTeamText')
  }
];

export const getNewsLinkCard = (t: TFunction): NewsItem => ({
  title: 'More news and updates on our website',
  link: config.exquanceLandingNewsPage
});
