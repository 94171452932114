import { handleBackendError, homeApiConfiguration } from '@services';
import {
  NewsItemDTOResponse,
  StartPageApi
} from '@xq/home-gateway-frontend-client';

export interface HomePageData {
  isNewMTVersionAvailable: boolean;
}

export interface HomeService {
  fetchData(): Promise<HomePageData>;

  fetchNews(): Promise<NewsItemDTOResponse[]>;
}

const homePageGateway = new StartPageApi(homeApiConfiguration);

export class HomeServiceApi implements HomeService {
  async fetchData(): Promise<HomePageData> {
    try {
      const response =
        await homePageGateway.startPageControllerCheckNewVersion();
      return {
        isNewMTVersionAvailable: response.isNewModeltreeVersionAvaliable
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async fetchNews(): Promise<NewsItemDTOResponse[]> {
    try {
      return await homePageGateway.startPageControllerGetNews();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}
